export interface DataLayerEvent extends InstanceType<any> {
  event?: string;
  category?: string;
  action?: string;
  label?: string;
  userId?: string;
  ecommerce?: Record<string, unknown> | null;
}

export interface PaymentEvents {
  stripe?: DataLayerEvent;
  onPurchase?: DataLayerEvent;
}

export type GTMConfig = {
  gtmId: string;
  auth?: string;
  preview?: string;
};

export enum MixpanelStoreName {
  Apple = 'App Store',
  Google = 'Play Store',
}

export enum MixpanelAppName {
  junior = 'junior_app',
  teen = 'senior_app',
  holyOwly = 'holyowly_app',
  jaramba = 'jaramba_app',
  code = 'code_app',
  geo = 'geo_app',
}

export enum MixpanelSubjectName {
  junior = 'junior-math',
  teen = 'teen-math',
  abc = 'abc',
  code = 'code',
  geo = 'geo',
  physics = 'physics',
  biology = 'biology',
  english = 'english',
  holyOwly = 'holy-owly',
  jaramba = 'jaramba',
}

export const MixpanelSubjectsPerApp: Record<MixpanelSubjectName, MixpanelAppName> = {
  code: MixpanelAppName.code,
  geo: MixpanelAppName.geo,
  'junior-math': MixpanelAppName.junior,
  english: MixpanelAppName.junior,
  abc: MixpanelAppName.junior,
  'teen-math': MixpanelAppName.teen,
  physics: MixpanelAppName.teen,
  biology: MixpanelAppName.teen,
  'holy-owly': MixpanelAppName.holyOwly,
  jaramba: MixpanelAppName.jaramba,
};

export enum EventDestination {
  Mixpanel = 'mixpanel',
  Braze = 'braze',
  ClickHouse = 'clickhouse',
}

export interface Event {
  name: string;
  timestamp: string;
  targets: EventDestination[];
  data?: Record<string, any>;
}

export interface PageTimeEventDto {
  sessionId: string;
  origin: string;
  url: string;
  referrer: string | null;
  userAgent: string;
  screenWidth: number;
  screenHeight: number;
  windowWidth: number;
  windowHeight: number;
  pixelRatio: number;
  eventAt: Date;
}

export type CheckoutLinkClickedSource =
  | 'blog_post'
  | 'header'
  | 'hero_banner'
  | 'mobile_menu'
  | 'picture_slider'
  | 'discover_subjects'
  | `subject_page_${MixpanelSubjectName}`
  | 'top_banner'
  | 'new_home_header'
  | 'new_home_hero_banner'
  | 'new_home_get_started'
  | 'new_home_stats'
  | 'new_home_try_today'
  | 'new_home_slidup_banner';
